const toQuery = (lat, long, city, country) => {
  if(lat && long) return `${lat}, ${long}`;
  if(city && country) return `${city}, ${country}`;
}

let directive;
if (window.EVA.googleMaps) {
  directive = {
    scope: { unit: '=evaLocationMap'},
    replace: true,
    controller: ["$scope", "$sce", ($scope, $sce) => {
      $scope.googleMapUrl = () => {
        const { unit } = $scope;
        const query = toQuery(unit.latitude, unit.longitude, unit.city, unit.$country.name)
        if(query)
          return $sce.trustAsResourceUrl(`https://www.google.com/maps/embed/v1/place?key=${window.EVA.googleMaps.key}&q=${encodeURIComponent(query)}`);
      }
    }],
    template(_element, attrs) {
      const width = attrs.width || 600;
      const height = attrs.height || 400;
      return `<iframe ng-src="{{ googleMapUrl() }}" width="${width}" height="${height}" frameborder="0"></iframe>`;
    }
  }
} else {
  directive = {};
}

export default () => directive
